import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class PerfilLogic {
    static instance = null;
    static createInstance() {
        var object = new PerfilLogic();
        return object;
    }

    static getInstance() {
        if (!PerfilLogic.instance) {
            PerfilLogic.instance = PerfilLogic.createInstance();
        }
        return PerfilLogic.instance;
    }

    constructor() {
    }

    async dropdownlist(){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var objRequest = {}

            var urlRequest = ENV_APIPATH+"/perfil/dropdownlist";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[InvitacionLogic] dorpdownlist()::ERROR >>> "+error);
        }
    }

}

const instance = PerfilLogic.getInstance();
export default instance;